import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { searchTypesenseDocuments } from 'utils/typeSense'

import { StyledForm, Input } from '../../styled'

import { closeMenu } from 'state/actions/drawer';
import { generateSearchResults } from 'state/actions/searchResults';
import createProperties from 'utils/functions/createProperties';

const SearchForm = ({ generateSearchResults, closeMenu, menuData, errorMessage, language }) => {
  const [isInputFieldEmpty, setErrorMessages] = useState(null);
  const [inputs, setInputs] = useState({});
  const { urls } = createProperties(language);
  
  const useSignUpForm = (callback) => {
    
    const handleSubmit = (event) => {
      if (event) event.preventDefault();
      searchTypesenseDocuments(inputs, setErrorMessages, generateSearchResults, urls)
    }

    const handleInputChange = (event) => {
      if(event) event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    return { handleSubmit, handleInputChange, inputs};
  }

  const handleCloseMenu = () => {
    if (inputs.searchbox != undefined && inputs.searchbox != "") {
      closeMenu(false)
    }
  }

  const { handleInputChange, handleSubmit} = useSignUpForm();

  return (
    <StyledForm onSubmit={handleSubmit} autocomplete="off">

      {
        isInputFieldEmpty === true ? 
        (
          <div style={{marginTop: '20px', marginBottom: '-15px', width: '100%'}}>
            <i className='warning-icon' />
            <span 
              style={{
                fontSize: '12px', 
                fontFamily: 'Raleway, sans-serif', 
                fontWeight: 400,
                color: "#46344e",
                textTransform: 'initial'
              }}
            >
              {errorMessage}
            </span> 
          </div>
        )
        : null
      }

      <Input 
        id="searchbox" 
        name="searchbox"
        aria-label={menuData.search_box_placeholder ? menuData.search_box_placeholder : ""}
        placeholder={menuData.search_box_placeholder ? menuData.search_box_placeholder : ""}
        onChange={handleInputChange} 
        value={inputs.searchbox}
        autoComplete="off"
        setErrorBorderColor={isInputFieldEmpty}
        mb='15px'
      />  
      <input 
        type="image" 
        name="submit" 
        src={menuData.search_icon.url}
        style={{position:'absolute', width:'24px', right:'15px', bottom: '80px'}} 
        onClick={() => handleCloseMenu()}
      />
    </StyledForm>
  )
}

const mapDispatchToProps = dispatch => ({
  closeMenu: isMenuClosed => dispatch(closeMenu(isMenuClosed)),
  generateSearchResults: results => dispatch(generateSearchResults(results))
})

export default connect(null, mapDispatchToProps)(SearchForm)

SearchForm.propTypes = {
  closeMenu: PropTypes.func,
  errorMessage: PropTypes.string,
  generateSearchResults: PropTypes.func,
  language: PropTypes.string,
  menuData: PropTypes.object
}
